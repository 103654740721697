import { Grid, RequestResult, Section } from '@frontend/shared/ui'
import React from 'react'
import { AppLayout } from '@frontend/domains/iframe/app'
import { Header } from '@frontend/domains/shared/carbon-offset'

export const Error = () => (
  <AppLayout>
    <Grid>
      <Header sideLinkTitle='Back' title='Application error' useButton onClick={() => window.history.back()} />

      <Section>
        <RequestResult
          description='The problem was already reported to our dev team. They will do the best to get things right.'
          title='We are sorry, but something does not work correctly in our application right now.'
          type='error'
        />
      </Section>
    </Grid>
  </AppLayout>
)
