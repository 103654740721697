import { Provider, FetchProviderProps } from 'use-http'
import React, { PropsWithChildren } from 'react'
import { Logger } from '@frontend/shared/logger'
import { useToast } from '@frontend/shared/ui'
import { nonEmptyBodyAttributes } from '@frontend/shared/utils'

export const Interceptor = ({ children }: PropsWithChildren<unknown>) => {
  const { showToastGenericError } = useToast()

  const options: FetchProviderProps['options'] = {
    interceptors: {
      request: async (requestParams) => {
        const { options } = requestParams || {}
        const headers = new Headers(options.headers)

        headers.set('Content-Type', 'application/json')

        options?.body
          && typeof options.body === 'string'
          && Object.assign(options, { body: nonEmptyBodyAttributes(options.body) })

        return options
      },
      async response({ response }) {
        if (500 <= response.status) {
          Logger.error(response)
          showToastGenericError()
        }

        return response
      },
    },
  }

  return <Provider options={options}>{children}</Provider>
}
