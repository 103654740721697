var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.3.1-0 [undefined]"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init, Replay } from '@sentry/nextjs'
import { getEnvironmentVariable, IS_DEV, IS_TEST } from '@frontend/shared/environment'

import packageJson from '../../package.json'

const sentryDns = getEnvironmentVariable('SENTRY_DNS')

if (sentryDns) {
  init({
    dsn: sentryDns,
    environment: getEnvironmentVariable('NEXT_PUBLIC_DEPLOYMENT_TARGET'),
    release: packageJson.version,
    integrations: [new Replay()],
    debug: IS_DEV || IS_TEST,
    attachStacktrace: IS_DEV || IS_TEST,
  })
} else {
  // eslint-disable-next-line no-console
  console.error('Sentry dns could not be loaded')
}
