import AppNext, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import '@frontend/shared/google-analytics'
import '@frontend/shared/validations-yup'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from '@sentry/nextjs'
import '@frontend/shared/logger'
import { ToastContainer } from '@frontend/shared/ui'
import { Slide } from 'react-toastify'
import { AppIframe, Interceptor } from '@frontend/domains/iframe/app'
import { Error } from '@frontend/domains/iframe/error'
import {
  GlobalStyle,
  mapPublicTheme,
  parsePublicTheme,
  themeIframe,
  ThemeProvider,
} from '@frontend/domains/shared/carbon-offset'

import 'react-tooltip/dist/react-tooltip.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-image-lightbox/style.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'

const CustomApp = ({ Component }: AppProps) => {
  const [theme, setTheme] = useState(themeIframe)

  useEffect(() => {
    const messageHandler = async (event) => {
      if (event.data.target === 'theme' && typeof event.data.theme === 'object') {
        const parsedTheme = await parsePublicTheme(event.data.theme)

        if (parsedTheme !== null) {
          const mappedTheme = await mapPublicTheme(parsedTheme)

          setTheme(mappedTheme)
        }
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [])

  return (
    <>
      <Head>
        <title>Earthbanc - Whitelabel Checkout</title>
      </Head>

      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <ErrorBoundary fallback={Error} showDialog>
          <ToastContainer autoClose={false} closeButton={false} draggable={false} transition={Slide} />

          <Interceptor>
            <AppIframe>
              <Component />
            </AppIframe>
          </Interceptor>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  )
}

CustomApp.getInitialProps = (props: AppContext) => {
  return AppNext.getInitialProps(props)
}

export default CustomApp
