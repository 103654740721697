import { Flex, logoPrimary, logoWhite } from '@frontend/shared/ui'
import React, { PropsWithChildren } from 'react'
import { Link } from '@frontend/domains/shared/components'
import { useTheme } from '@frontend/domains/shared/carbon-offset'

import { AppContainer, AppLayoutWrapper, ImageWrapper } from './app-layout.styles'

export const AppLayout = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme()

  return (
    <Flex justifyContent='center' pb={['16rem', '11rem', null, '8rem']}>
      <AppContainer>
        <AppLayoutWrapper>
          <ImageWrapper>
            <Link href='/'>
              <img
                alt='Earthbanc - logo'
                height={28}
                src={theme.components.logo === 'light' ? logoWhite : logoPrimary}
                width={166}
              />
            </Link>
          </ImageWrapper>

          {children}
        </AppLayoutWrapper>
      </AppContainer>
    </Flex>
  )
}
