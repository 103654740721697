import { Flex } from '@frontend/shared/ui'
import { styled } from '@frontend/shared/theme'

export const AppContainer = styled(Flex).attrs(({ theme }) => ({
  position: 'relative',
  flexDirection: 'column',
  width: ['100%', theme.breakpoints[0], theme.breakpoints[1], theme.breakpoints[2], theme.breakpoints[3]],
}))``

export const AppLayoutWrapper = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  px: [4, null, '0rem'],
  pt: ['2.5rem', '6.25rem'],
}))``

export const ImageWrapper = styled(Flex).attrs(() => ({
  justifyContent: ['center'],
  position: ['static', 'absolute'],
  top: '2.5rem',
  mb: [6, null],
}))``

export const FooterContainer = styled(Flex).attrs(() => ({
  bottom: '0rem',
  position: 'fixed',
  width: '100%',
  justifyContent: 'center',
}))`
  background-color: ${({ theme }) => theme.colors.secondary.bgGray.main};
`
